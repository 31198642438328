import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../utils/SectionProps";
import SectionHeader from "../components/sections/partials/SectionHeader";
import Table from "react-bootstrap/Table";
import { clanovi } from "../clanovi";
const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Members = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "",
    paragraph: "Popis članova kluba i njihov raiting.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div className={tilesClasses}>
          <div className="reveal-from-bottom" data-reveal-delay="400" style={{"width" : "100%", "overflow": "auto"}}>
            
            <Table  responsive='sm' striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Ime</th>
                  <th>Titula</th>
                  <th>INT Raiting</th>
                  <th>NAT Raiting</th>
                  <th>RPD Raiting</th>
                  <th>BLZ Raiting</th>
                  <th>Godina rođenja</th>
                  <th>Kategorija</th>
                </tr>
              </thead>
              <tbody>
                {clanovi.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.ime}</td>
                      <td>{item.titula}</td>
                      <td>{item.int_raiting}</td>
                      <td>{item.nat_raiting}</td>
                      <td>{item.rpd_raiting}</td>
                      <td>{item.blz_raiting}</td>
                      <td>{item.godina_rodjenja}</td>
                      <td>{item.kategorija}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            
          
            <p>
              <i>Podatci preuzeti s HŠS.</i>
            </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Members.propTypes = propTypes;
Members.defaultProps = defaultProps;

export default Members;
