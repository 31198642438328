import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  closeHidden: PropTypes.bool,
  news: PropTypes.object,
  video: PropTypes.string,
  videoTag: PropTypes.oneOf(["iframe", "video"]),
};

const defaultProps = {
  children: null,
  show: false,
  closeHidden: false,
  news: {},
  video: "",
  videoTag: "iframe",
};

const Modal = ({
  className,
  children,
  handleClose,
  show,
  closeHidden,
  news,
  video,
  videoTag,
  ...props
}) => {
  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", stopProgagation);
    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", stopProgagation);
    };
  });

  useEffect(() => {
    handleBodyClass();
  }, [props.show]);

  const handleBodyClass = () => {
    if (document.querySelectorAll(".modal.is-active").length) {
      document.body.classList.add("modal-is-active");
    } else {
      document.body.classList.remove("modal-is-active");
    }
  };

  const keyPress = (e) => {
    e.keyCode === 27 && handleClose(e);
  };

  const stopProgagation = (e) => {
    e.stopPropagation();
  };

  const classes = classNames(
    "modal",
    show && "is-active",
    video && "modal-video",
    className
  );

  return (
    <>
      {show && (
        <div
          {...props}
          className={classes}
          onClick={handleClose}
          style={{ width: "100%", height: "100%" }}
        >
          <div
            style={{ maxWidth: "100vh" }}
            className="modal-inner"
            onClick={stopProgagation}
          >
            <div style={{ margin: "40px", padding: "8px" }}>
              <h3 className="mt-0 mb-12">{news.naslov}</h3>
              <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  {news.datum}
                  </div>
              <p className="m-0">{news.tekst}</p>
              
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
