export const clanovi = [
  {
    ime: "Zagorac, Marko",
    titula: "",
    int_raiting: "0",
    nat_raiting: "1600",
    rpd_raiting: "1423",
    blz_raiting: "1357",
    godina_rodjenja: "1982",
    kategorija: "",
  },
  {
    ime: "Radić, Luka D",
    titula: "IV",
    int_raiting: "0",
    nat_raiting: "1759",
    rpd_raiting: "1152",
    blz_raiting: "",
    godina_rodjenja: "2008",
    kategorija: "U16",
  },
  {
    ime: "Zagorac, Lara",
    titula: "I",
    int_raiting: "1277",
    nat_raiting: "1884",
    rpd_raiting: "1387",
    blz_raiting: "",
    godina_rodjenja: "2010",
    kategorija: "U16",
  },
  {
    ime: "Sinjeri, Ivan",
    titula: "",
    int_raiting: "1107",
    nat_raiting: "1614",
    rpd_raiting: "1100",
    blz_raiting: "",
    godina_rodjenja: "2007",
    kategorija: "U16",
  },
  {
    ime: "Mikulić, Petar",
    titula: "IV",
    int_raiting: "1040",
    nat_raiting: "1753",
    rpd_raiting: "1273",
    blz_raiting: "",
    godina_rodjenja: "2007",
    kategorija: "U16",
  },
  {
    ime: "Vujić, Vlado",
    titula: "",
    int_raiting: "",
    nat_raiting: "1660",
    rpd_raiting: "1590",
    blz_raiting: "1616",
    godina_rodjenja: "1962",
    kategorija: "",
  },
  {
    ime: "Jerković, Maroje",
    titula: "MK",
    int_raiting: "2185",
    nat_raiting: "2172",
    rpd_raiting: "2005",
    blz_raiting: "2087",
    godina_rodjenja: "1992",
    kategorija: "",
  },
  {
    ime: "Huskić, Arif",
    titula: "IV",
    int_raiting: "1730",
    nat_raiting: "1764",
    rpd_raiting: "1807",
    blz_raiting: "1732",
    godina_rodjenja: "1943",
    kategorija: "U65",
  },
  {
    ime: "Pavičić, Tomislav",
    titula: "MK",
    int_raiting: "2133",
    nat_raiting: "2133",
    rpd_raiting: "",
    blz_raiting: "2230",
    godina_rodjenja: "1974",
    kategorija: "",
  },
  {
    ime: "Pavešić, Robert",
    titula: "IV",
    int_raiting: "1690",
    nat_raiting: "1734",
    rpd_raiting: "1960",
    blz_raiting: "1798",
    godina_rodjenja: "1964",
    kategorija: "",
  },
  {
    ime: "Ban, Ivoslav",
    titula: "I",
    int_raiting: "2002",
    nat_raiting: "2015",
    rpd_raiting: "1959",
    blz_raiting: "1801",
    godina_rodjenja: "1962",
    kategorija: "",
  },
  {
    ime: "Kovačić, Dragutin",
    titula: "I",
    int_raiting: "1957",
    nat_raiting: "1978",
    rpd_raiting: "1900",
    blz_raiting: "1981",
    godina_rodjenja: "1954",
    kategorija: "U65",
  },
  {
    ime: "Šverko, Mladen",
    titula: "I",
    int_raiting: "1998",
    nat_raiting: "2046",
    rpd_raiting: "1864",
    blz_raiting: "1908",
    godina_rodjenja: "1958",
    kategorija: "",
  },
  {
    ime: "Petrić, Ivica",
    titula: "I",
    int_raiting: "2034",
    nat_raiting: "2044",
    rpd_raiting: "1942",
    blz_raiting: "1944",
    godina_rodjenja: "1975",
    kategorija: "",
  },
  {
    ime: "Relja, Marin",
    titula: "I",
    int_raiting: "1994",
    nat_raiting: "2026",
    rpd_raiting: "2102",
    blz_raiting: "2049",
    godina_rodjenja: "1978",
    kategorija: "",
  },
  {
    ime: "Kukuljan, Vlatko",
    titula: "I",
    int_raiting: "2001",
    nat_raiting: "2034",
    rpd_raiting: "1985",
    blz_raiting: "2036",
    godina_rodjenja: "1990",
    kategorija: "",
  },
  {
    ime: "Dizdarević, Mirsad",
    titula: "I",
    int_raiting: "1778",
    nat_raiting: "2000",
    rpd_raiting: "1878",
    blz_raiting: "1846",
    godina_rodjenja: "1968",
    kategorija: "",
  },

];
