import React, {useState} from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import {novosti} from '../../novosti';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const [ModalActive, setModalActive] = useState(false);
  const [SelectedNews, setSelectedNews] = useState(false);


  const openModal = (e) => {

    setModalActive(true);
    setSelectedNews(e)
  }

  const closeModal = (e) => {
    e.preventDefault();
    setModalActive(false);
  }   

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Poslijednje Novosti',
    paragraph: ''
  };

  
  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div>{novosti.map((value, index)=>{
              if(index <= 2){

                return(
                  <div key={index} className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    {value.datum}
                    </div>
                  <h3 style={{"cursor": "pointer"}} onClick={()=>openModal(index)}className="mt-0 mb-12">
                    {value.naslov}
                    </h3>
                  <p className="m-0">
                    {value?.sazetak}
                     </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require(`./../../assets/images/features-split-image-0${index+1}.jpg`)}
                    alt={`Features Split ${index+1}`}
                    width={528}
                    height={396} />
                </div>
              </div>
                );
              }else return null;
            })}</div>

          

          </div>
          <Modal
            id="video-modal"
            show={ModalActive}
            handleClose={closeModal}
            news={novosti[SelectedNews]}
          /> 
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;