export const novosti = [
  {
    naslov: "Novo Ruho ŠK Rječine",
    datum: "03.06.2021",
    sazetak:
      "Novi dizajn web-stranice i nove mogućnosti za kontaktiranje",
    tekst:
      "Pozdrav svim posjetiocima, započinjemo novo poglavlje za šahovski klub Rječina. Ažurirali smo web stranicu s novim dizajnom i novim mogućnostima. Objavljivati ćemo novosti vezane za klub i uspjehe članova kluba. U budućnosti ćemo također dodati i galeriju slika s naših turnira. Lijep pozdrav! -Antonio Ban",
  }
];
