import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../utils/SectionProps";
//import SectionHeader from "../components/sections/partials/SectionHeader";
import Image from "../components/elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const About = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  // const sectionHeader = {
  //   title: "Poslijednje Novosti",
  //   paragraph: "",
  // };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                
                <h3 className="mt-0 mb-12">  Šahovski Klub Rječina</h3>
                </div>
                <p className="m-0">
                  Šahovski klub Rječina - Dražice počeo je djelovati u jesen
                  1999. na inicijativu Gorana Mufića i Ljube Kukuljana, a
                  službeno je osnovan 21.02.2000. god. Zanimljivo je da je
                  reprezentativac Hrvatske i najbolji igrač završnice nedavne
                  Online Šahovske olimpijade - Marin Bosiočić, upravo protiv
                  članova Rječine odigrao prvu simultanku u životu! Jedini remi
                  tada je izvukao Vlatko Kukuljan koji je bio najuspješniji
                  mladi igrač Rječine u prvim godinama djelovanja Kluba. Velike
                  zasluge u početnim godinama djelovanja Kluba je imao i pokojni
                  Ante Fućak, pa Rječina njemu u čast svake godine organizira
                  memorijalni turnir. Od 2006. godine Klub počinje sustavno
                  raditi s mladima, te razvija međunarodnu suradnju s klubovima
                  u Slovačkoj i Austriji. Najveći su uspjesi bili u ženskoj
                  kadetskoj konkurenciji jer su sestre Agata i Viktorija Vujić,
                  te Jelena i Lana Dašić bile ekipne prvakinje Hrvatske! U
                  pojedinačnoj konkurenciji Agata Vujić je bila 2. do 8 godina,
                  a Viktorija Vujić i Jelena Dašić su bile 3. do 15 odnosno 17
                  godina. Najveći pojedinačni uspjeh Kluba je prvo mjesto u
                  Hrvatskoj Lare Zagorac prošle godine u uzrastu do 9 godina, a
                  velike pohvale zaslužuje i sadašnja kadetska ekipa Rječine -
                  Lara Zagorac, Petar Mikulić, Luka Radić i Ivan Sinjeri za
                  nedavnu pobijedu na otvorenom prvenstvu naše Županije!
                  Uspješni su bili i seniori, koji su se ove godine po treći
                  puta plasirali u 2. ligu Zapad, a najveći uspjeh je bilo 9.
                  mjesto na otvorenom prvenstvu Slovačke u ubrzanom šahu 2009.
                  godine ekipe u sastavu: Marin Relja, Božo Zrilić, Mario Štefan
                  i Ivica Petrić.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require(`../assets/images/about.jpg`)}
                  alt={`Features Split 1`}
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

About.propTypes = propTypes;
About.defaultProps = defaultProps;

export default About;
