import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
//import ReactGA from 'react-ga';
import App_style from './App_style'
import {

  withStyles,

} from "@material-ui/core";
// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Contact from './views/Contact'
import About from './views/About'
import Members from './views/Members'
import News from './views/News'

// Initialize Google Analytics
//ReactGA.initialize(process.env.REACT_APP_GA_CODE);


// const trackPage = page => {
//   ReactGA.set({ page });
//   ReactGA.pageview(page);
// };

const App = () => {
  
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    //const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    //trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/kontakt" component={Contact} layout={LayoutDefault} />
          <AppRoute exact path="/o-nama" component={About} layout={LayoutDefault} />
          <AppRoute exact path="/clanovi" component={Members} layout={LayoutDefault} />
          <AppRoute exact path="/novosti" component={News} layout={LayoutDefault} />
          <AppRoute exact path="/novosti/:id" component={News} layout={LayoutDefault} />
          {/* <AppRoute path="*" component={Home} layout={LayoutDefault} /> */}
        </Switch>
      )} />
  );
}

export default withStyles(App_style)(App);